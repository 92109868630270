<template>
    <div class="faq-section__wrapper">
        <PageNavigationMenu
            class="nav__wrapper mt-5"
            :buttons="buttonsLinks"
            @linkClick="handleLinkClick"
            :selected-link="currentLink"
        />
        <div class="faq-section">
            <div class="title-container">
                <h2 class="title">{{ $t('main.faqSection.title') }}</h2>
                <p class="mb-34">{{ $t('main.faqSection.subTitle') }}</p>
            </div>
            <div>
                <div class="section" v-for="section in sections" :key="section">
                    <div :id="section.id" v-if="section.items.length">
                        <h3 class="section-title">{{ $filters.getTranslationByKey(section.name) }}</h3>
                        <Accordion>
                            <AccordionItem
                                class="accordion"
                                v-for="item in section.items"
                                :key="item.id"
                                :title="$filters.getTranslationByKey(item.question)"
                                :text="$filters.getTranslationByKey(item.answer)"
                            />
                        </Accordion>
                    </div>
                </div>
            </div>
            <GetInTouchSection
                class="get-in-touch-section"
                :title="$t('main.faqSection.cantFindAnswers')"
                :with-image="false"
            />
        </div>
    </div>
</template>

<script>
    import Accordion from '@/components/Common/Accordion/Accordion.vue';
    import AccordionItem from '@/components/Common/Accordion/AccordionItem.vue';
    import GetInTouchSection from '@/components/MainSections/GetInTouchSection';
    import PageNavigationMenu from '@/components/Common/NavMenu/PageNavigationMenu';
    import { FAQSectionApi } from '@/api';

    export default {
        name: 'FAQ',
        components: { GetInTouchSection, AccordionItem, Accordion, PageNavigationMenu },
        data() {
            return {
                currentLink: null,
                sections: [],
            };
        },
        methods: {
            async fetchData() {
                const response = await FAQSectionApi.getAll();
                this.sections = response.data;
            },
            handleLinkClick(link) {
                this.currentLink = link;
            },
        },
        computed: {
            buttonsLinks() {
                let arr = [];
                this.sections.forEach((section) => {
                    if (section.items.length) {
                        arr.push({
                            name: section.id,
                            title: this.$filters.getTranslationByKey(section.name),
                        });
                    }
                });

                return arr;
            },
        },
        watch: {
            buttonsLinks: {
                handler(value) {
                    if (value.length) {
                        this.currentLink = this.buttonsLinks[0];
                    }
                },
                immediate: true,
            },

            'currentLink.name': {
                handler(val) {
                    if (document.getElementById(val)) {
                        document.getElementById(val).scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                },
            },
        },
        async mounted() {
            await this.fetchData();
        },
    };
</script>

<style lang="scss" scoped>
    .faq-section__wrapper {
        @include column-align-stretch;

        .faq-section {
            max-height: calc(100vh - 136px);
            overflow-y: scroll;
            padding-top: 25px;
            padding-bottom: 25px;

            &::-webkit-scrollbar {
                display: none;
            }

            p {
                font-weight: 700;
                opacity: 0.7;
                margin-bottom: 15px;
            }

            .section {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                margin-bottom: 32px;

                &:last-of-type {
                    border-bottom: none !important;
                }

                .section-title {
                    margin-bottom: 15px;

                    color: $primary;
                    word-break: break-all;
                    width: 90%;
                }

                .accordion {
                    padding: 0;
                }
            }
        }

        .nav__wrapper {
            margin-top: 25px;
        }
    }

    @include media($lg) {
        .faq-section__wrapper {
            @include row-align-start;

            padding-top: 50px;
            padding-bottom: 0;

            .faq-section {
                max-height: calc(100vh - 126px);
                overflow-y: scroll;
                margin-left: 100px;
                max-width: 600px;

                h2 {
                    font-size: $font-34;
                }

                .get-in-touch-section {
                    @include column-align-center;
                    justify-content: stretch;

                    min-width: 100%;

                    margin-bottom: 40px;
                    margin-top: 20px;

                    :deep(.touch-section__inner) {
                        width: 100%;
                    }
                }
            }

            .accordion {
                padding: 0;

                width: 600px;
            }

            .nav__wrapper {
                width: 180px;
            }
        }
    }

    @include media($xl) {
        .faq-section__wrapper {
            .faq-section {
                margin-left: 200px;
                max-width: 800px;
            }
        }
    }

    @include media($xxl) {
        .faq-section__wrapper {
            .faq-section {
                margin-left: 300px;
            }
        }
    }
</style>
