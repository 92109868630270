<template>
    <div class="nav-menu" v-if="buttons">
        <SlideDropDown
            :is-menu-slide="provideApp.$screenInnerWidth >= 1024"
            :isNavMenuOpened="isNavMenuOpened"
            @onSliderToggle="onSliderToggle"
        >
            <template #title v-if="provideApp.$screenInnerWidth <= 1024">
                <CRButton pattern="section" :checked="progress?.[selectedLink.name]" active>
                    {{ selectedLink?.title }}
                </CRButton>
            </template>
            <template #content>
                <CRButton
                    class="menu-link"
                    v-for="link in neededArray"
                    :key="link.name"
                    pattern="section"
                    :active="link.name === selectedLink.name"
                    :to="{ name: pageName, hash: '#' + link.name }"
                    :checked="progress?.[link.name]"
                    @click="setLink(link)"
                >
                    {{ link.title }}
                </CRButton>
            </template>
        </SlideDropDown>
    </div>
</template>

<script>
    import SlideDropDown from '@/components/Common/DropDown/SlideDropDown.vue';
    import CRButton from '@/components/Common/Buttons/CRButton';

    export default {
        name: 'PageNavigationMenu',
        components: { SlideDropDown, CRButton },
        inject: ['provideApp'],
        props: {
            buttons: {
                type: Array,
                default: () => [],
            },
            progress: {
                type: Object,
                default: () => {},
            },
            pageName: {
                type: String,
                default: '',
            },
            selectedLink: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['linkClick'],
        data() {
            return {
                filteredLinksData: [],
                isNavMenuOpened: true,
            };
        },
        computed: {
            neededArray() {
                return this.provideApp.$screenInnerWidth <= 1024 ? this.filteredLinksData : this.buttons;
            },
        },
        watch: {
            selectedLink: {
                handler(value) {
                    this.filteredLinksData = this.buttons.filter((item) => item.name !== value.name);
                },
                immediate: true,
            },
        },
        methods: {
            setLink(link) {
                this.isNavMenuOpened = false;
                this.$emit('linkClick', link);
            },

            onSliderToggle(val) {
                this.isNavMenuOpened = val;
            },
        },
    };
</script>

<style lang="scss" scoped>
    // nav-menu
    .nav-menu {
        padding-bottom: 1px;

        &.scroll {
            position: sticky;
            top: 90px;

            z-index: $index-200;
        }
    }

    // menu-link
    .menu-link {
        margin-top: 10px;
    }
</style>
